import React from 'react';
import {useUserContext} from "../../hooks";
import {Alert} from "reactstrap";
import {Link} from "react-router-dom";

export function getReadySteadyCookStatus(readySteadyCook) {
  const { ready_complete, steady_complete, cook_complete } = readySteadyCook;
  const numIncomplete = (ready_complete ? 0 : 1) + (steady_complete ? 0 : 1) + (cook_complete ? 0 : 1)
  const color = {
    3: 'danger',
    2: 'tlg-orange-500',
    1: 'warning'
  }[numIncomplete];
  const incompleteLabels = [
    ready_complete ? null : 'Ready',
    steady_complete ? null : 'Steady',
    cook_complete ? null : 'Cook',
  ].filter(i => i);

  return {
    color,
    incompleteLabels,
    numIncomplete
  }
}

export function RSCFormAlert({ values }) {
  const { numIncomplete } = getReadySteadyCookStatus(values);

  function getText() {
    if (numIncomplete === 0) {
      return (
          <>
            Thank you for confirming your commitment to safe practice at Make Lunch! May your sessions be a blessing to all involved.
          </>
      )
    } else {
      return (
          <>
            Please confirm that your local club is adhering to the safe operating method outlined by TLG. These
            safeguarding, compliance and training checks are required to ensure safe practice among our church partners
            and volunteers, and to protect the children and families we connect with through Make Lunch.
          </>
      )
    }
  }

  return (
      <Alert color={numIncomplete === 0 ? 'success' : 'danger'}>
        {getText()}
      </Alert>
  )
}

export function RSCAlert() {
  const { user } = useUserContext();
  const { ready_steady_cook } = user;
  const { ready_complete, steady_complete, cook_complete } = ready_steady_cook;

  if (ready_complete && steady_complete && cook_complete) {
    return null;
  }

  const { incompleteLabels, color } = getReadySteadyCookStatus(ready_steady_cook);

  function getText() {
    if (incompleteLabels.length === 1) {
      return (
        <>
          <strong>One section of your <Link to="/ready_steady_cook">Ready Steady Cook</Link> bar is incomplete.</strong> Please update to confirm you have completed all the necessary steps to ensure your club is safe and effective. Please contact your Church Relationship Lead (CRL) if you have any questions or need any support.
        </>
      )
    } else if (incompleteLabels.length === 2) {
      return (
        <>
          <strong>Two sections of your <Link to="/ready_steady_cook">Ready Steady Cook</Link> are incomplete.</strong> Please update to confirm you have undertaken all the necessary steps to ensure your club is safe and effective. Please contact your Church Relationship Lead (CRL) if you have any questions or need any support.
        </>
      )
    } else {
      return (
        <>
          <strong>No sections of your <Link to="/ready_steady_cook">Ready Steady Cook</Link> bar are complete.</strong> Please update to confirm you have undertaken all the necessary steps to ensure your club is safe and effective. Please contact your Church Relationship Lead (CRL) if you have any questions or need any support.
        </>
      )
    }
  }

  return (
    <Alert color={color}>
      {getText()}
    </Alert>
  )
}