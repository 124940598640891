import React, {memo, useEffect, useMemo, useState} from "react";
import { Page, Select, FormGroup, SubmitButton } from "components/common";
import {useUserContext, useFormikContext, useAsyncEffect, useModal} from "hooks";
import { Formik, Field } from "formik";
import { range, map, keys, reduce, get } from "lodash";
import moment from "moment";
import {Table as ReactstrapTable, Row, Col, Collapse, ModalBody, ModalHeader} from "reactstrap";
import download from "downloadjs";
import { CollapseHeader } from "components/common";
import { useQueryState } from "hooks";
import {
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";
import { NotificationManager } from "react-notifications";
import {mealsAndMoreFundingOptions} from "../Organisations/Edit";
import {EthnicityGraph} from "../../features/ethnicity_capture/EthnicityGraph";
import {VolunteerAgeGraph} from "./VolunteerAgeGraph";
import {EthnicityCaptureOtherData} from "../../features/ethnicity_capture/EthnicityCaptureOtherData";
import {ActivitiesOtherData} from "../../features/metrics/ActivitiesOtherData";
import {ReturningRegistrationsGraph} from "../../features/metrics/ReturningRegistrationsGraph";
import {AverageVolunteersPerSession} from "../../features/metrics/AverageVolunteersPerSession";
import {RegistrationStatementsGraph} from "../../features/metrics/RegistrationStatementsGraph";
import {ActivityGraphs} from "../../features/metrics/ActivityGraphs";
import DatePicker from "../../components/common/DatePicker";
import {
  ParticipantFeedbackGraph
} from "../../features/participant_feedback";
import {SurveyFreeTextAnswers} from "../../features/participant_feedback/components/SurveyFreeTextAnswers";
import {ReadySteadyCookGraph} from "../../features/ready_steady_cook/ReadySteadyCookGraph";

const Table = ({ title, className, ...props }) => {
  return (
    <div className={className}>
      {title ? <h5 className="tw-text-left">{title}</h5> : null}
      <ReactstrapTable size="sm" bordered {...props} />
    </div>
  );
};

export const CombinedGoldenNumbers = memo(({ data, isStaff }) => {
  const mapping = {
    number_of_sessions: "No of Sessions",
    child_portions: "Total No of Children Portions + Children Meals",
    adult_portions: "Total No of Adult Portions + Adult Meals",
    unique_adults: "No of Unique Adults",
    unique_children: "No of Unique Children",
    unique_volunteers: "No of Unique Volunteers",
    unique_children_accessing_wider_support:
      "Unique Children Accessing Wider Support",
    unique_adults_accessing_wider_support:
      "Unique Adults Accessing Wider Support",
    organisations_reporting: isStaff
      ? "Clubs Reporting"
      : null,
    organisations_open: isStaff ? "Clubs Open" : null,
    clubs_at_least_1_accessing_wider_support: isStaff ? 'Clubs with at least one person accessing wider support of the Church' : '',
  };

  const holidays = keys(data["number_of_sessions"]["holidays"]);

  return (
    <Table title="Combined Golden Numbers" className="tw-text-right">
      <thead>
        <tr>
          <td />
          {holidays.map((i) => (
            <td key={i} className="tw-font-medium bg-tlg-teal tw-text-sm">
              {i}
            </td>
          ))}
          <td className="tw-font-medium bg-tlg-teal">Year to Date</td>
        </tr>
      </thead>
      <tbody>
        {map(data, (v, k) => (
          mapping[k] ? (
            <tr key={k}>
              <td className="tw-font-medium">{mapping[k] || k}</td>
              {map(v.holidays, (v, k) => (
                <td key={k}>{v}</td>
              ))}
              <td>{v.total}</td>
            </tr>
          ) : null
        ))}
      </tbody>
    </Table>
  );
});

export const ParcelGoldenNumbers = memo(({ data, isStaff }) => {
  const { isTLG, isBoxesOfHope } = useUserContext();

  const mapping = {
    number_of_provision_sessions: "No of Provision Sessions",
    number_of_parcels: "Total No of Parcels",
    number_of_portions: "Total No of Portions",
    unique_families_served: "No of Unique Families Served",
    unique_adults: "No of Unique Adults",
    adult_portions: "No of Adult Portions",
    unique_children: "No of Unique Children",
    unique_volunteers: isTLG ? "No of Unique Volunteers" : '',
    child_portions: "No of Children's Portions",
    organisations_reporting: isStaff ? "Clubs Reporting" : '',
    unique_children_accessing_wider_support: isTLG ?
      "Unique Children Accessing Wider Support" : '',
    unique_adults_accessing_wider_support: isTLG ?
      "Unique Adults Accessing Wider Support" : '',
    unique_parcels_with_wellbeing_resources: isBoxesOfHope ? 'Total No of Parcels with Well Being Resources': '',
    clubs_at_least_1_accessing_wider_support: isStaff ? 'Clubs with at least one person accessing wider support of the Church' : '',
  };

  const holidays = keys(data["number_of_provision_sessions"]["holidays"]);

  return (
    <Table title="Golden Numbers - Parcel Provision" className="tw-text-right">
      <thead>
        <tr>
          <td />
          {holidays.map((i) => (
            <td key={i} className="tw-font-medium bg-tlg-teal">
              {i}
            </td>
          ))}
          <td className="tw-font-medium bg-tlg-teal">Year to Date</td>
        </tr>
      </thead>
      <tbody>
        {map(data, (v, k) =>
          mapping[k] ? (
            <tr key={k}>
              <td className="tw-font-medium">{mapping[k]}</td>
              {map(v.holidays, (v, k) => (
                <td key={k}>{v}</td>
              ))}
              <td>{v.total}</td>
            </tr>
          ) : null
        )}
      </tbody>
    </Table>
  );
});

export const GoldenNumbers = memo(({ data, isStaff }) => {
  const mapping = {
    number_of_sessions: "Number of sessions",
    eligible_child_meals: "Child Meals",
    unique_eligible_children: "Unique Children",
    eligible_parent_meals: "Parent/Carer Meals",
    unique_eligible_parents: "Unique Parents/Carers",
    unique_volunteers: "Unique Volunteers",
    unique_helpers: "Unique Parent/Carer Helpers",
    unique_young_helpers: "Unique Young Helpers",
    children_accessed_wider_support_of_church:
      "Children who have accessed the wider support of the church",
    parents_accessed_wider_support_of_church:
      "Parents/Carers who have accessed the wider support of the church",
    organisations_open: isStaff ? "Clubs Open" : null,
    organisations_reporting: isStaff
      ? "Clubs Reporting"
      : null,
    clubs_at_least_1_accessing_wider_support: isStaff ? 'Clubs with at least one person accessing wider support of the Church' : '',
  };

  const holidays = keys(data["number_of_sessions"]["holidays"]);

  return (
    <Table title="Golden Numbers - Lunch Club" className="tw-text-right">
      <thead>
        <tr>
          <td />
          {holidays.map((i) => (
            <td key={i} className="tw-font-medium bg-tlg-teal">
              {i}
            </td>
          ))}
          <td className="tw-font-medium bg-tlg-teal">Year to Date</td>
        </tr>
      </thead>
      <tbody>
        {map(data, (v, k) =>
          mapping[k] ? (
            <tr key={k}>
              <td className="tw-font-medium">{mapping[k]}</td>
              {map(v.holidays, (v, k) => (
                <td key={k}>{v}</td>
              ))}
              <td>{v.total}</td>
            </tr>
          ) : null
        )}
      </tbody>
    </Table>
  );
});

export const Demographics = memo(({ data }) => {
  const {
    all_children,
  } = data;

  const purple = { className: "bg-tlg-purple-light" };
  const headerMapping = {
    "Prefer not to declare": (
      <>
        Prefer not
        <br />
        to declare
      </>
    ),
  };

  return (
    <div className="tw-text-right">
      <Row>
        <Col>
          <Table
            title={
              <>
                Age and Gender of Children
              </>
            }
          >
            <thead>
              <tr>
                <td />
                {map(all_children.years["1yrs old"].data, (v, k) => (
                  <td {...purple} key={k}>
                    {headerMapping[k] || k}
                  </td>
                ))}
                <td {...purple}>Total</td>
              </tr>
            </thead>
            <tbody>
              {map(all_children.years, (v, k) => (
                <tr key={k}>
                  <td {...purple}>{k}</td>
                  {map(v.data, (v, k) => (
                    <td key={k}>{v}</td>
                  ))}
                  <td>{v.total}</td>
                </tr>
              ))}
              <tr>
                <td {...purple}>Total</td>
                {map(all_children.gender_totals, (v, k) => (
                  <td key={k}>{v}</td>
                ))}
                <td>{all_children.total}</td>
              </tr>
              <tr>
                <td {...purple}>Percentage Split</td>
                {map(all_children.percentages, (v, k) => (
                  <td key={k}>{v}%</td>
                ))}
                <td />
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
});

const AccessingSupportChart = ({
  data: dataProp,
  options,
  colors: colorsProp,
}) => {
  const colors = colorsProp || ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const data = useMemo(() => {
    return reduce(
      dataProp,
      (acc, i, key) => {
        if (!options[key]) return acc;
        acc.push({
          name: `${options[key]}: ${i}`,
          value: i,
        });
        return acc;
      },
      []
    );
  }, [dataProp, options]);

  return (
    <ResponsiveContainer minHeight={310}>
      <PieChart>
        <Pie data={data} label dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend layout="vertical" verticalAlign="bottom" />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

const AccessingSupportNumbers = ({ data }) => {
  const { parents, children, meta } = data;
  const { accessing_support_options } = meta;

  return (
    <Row className="mb-4">
      <Col>
        <h5 className="tw-text-left">
          Children accessing wider support of the church{" "}
          <span className="text-muted">(total: {children.total})</span>
        </h5>
        <div className="tw-relative">
          <AccessingSupportChart
            data={children}
            options={accessing_support_options}
          />
        </div>
      </Col>
      <Col>
        <h5 className="tw-text-left">
          Parents/Carers accessing wider support of the church{" "}
          <span className="text-muted">(total: {parents.total})</span>
        </h5>
        <div className="tw-relative">
          <AccessingSupportChart
            data={parents}
            options={accessing_support_options}
          />
        </div>
      </Col>
    </Row>
  );
};

const THIS_YEAR = moment().year();
const YEARS = range(2020, THIS_YEAR + 2).map((i) => ({ label: i, value: i }));

const organisationTypes = [
  {
    label: 'TLG',
    value: 'tlg'
  },
  {
    label: 'Boxes of Hope',
    value: 'boh'
  },
  {
    label: 'All',
    value: 'all'
  },
];

export const Dashboard = ({ client }) => {
  const { isStaff, isTLG } = useUserContext();
  const [freeTextAnswers, setFreeTextAnswers] = useState(null);
  const { values, isValid, setSubmitting, setFieldValue } = useFormikContext();
  const {
    year,
    organisation_type,
    organisation_names,
    regions,
    regionalLeaderOptions,
    regional_leaders,
    regionOptions,
    organisationNameOptions,
    demographics,
    goldenNumbers,
    parcelGoldenNumbers,
    accessingSupportNumbers,
    combinedGoldenNumbers,
    userEthnicity,
    participantEthnicity,
    volunteerAges,
    tags,
    metrics,
    participantFeedback,
    date_from,
    date_to,
    readySteadyCookStats
  } = values;
  const [isDemographicsOpen, setIsDemographicsOpen] = useQueryState(
    "demographics_expanded"
  );
  const [isVolunteersOpen, setIsVolunteersOpen] = useQueryState(
    "volunteers_expanded"
  );
  const [isParticipantFeedbackOpen, setIsParticipantFeedbackOpen] = useQueryState(
    "participant_feedback_expanded"
  );
  const [isReadySteadyCookOpen, setIsReadySteadyCookOpen] = useQueryState(
    "ready_steady_cook_expanded"
  );

  const {
    Modal: FreeTextAnswerModal,
    toggle: toggleFreeTextAnswerModal,
    setIsOpen: setIsFreeTextAnswerModalOpen,
    isOpen: isFreeTextAnswerModalOpen,
  } = useModal()

  useAsyncEffect(async () => {
    await load();
  }, []);

  useAsyncEffect(async () => {
    if (!isStaff) {
      setFieldValue("organisationNameOptions", []);
      setFieldValue("regionOptions", []);
      setFieldValue("regionalLeaderOptions", []);
      return;
    }

    const [
      regionOptions,
      regionalLeaderOptions,
      organisationNameOptions,
    ] = await Promise.all([
      client.get("organisations/regions").get("data"),
      client
        .get("regional_leaders")
        .get("data")
        .map((i) => ({
          label: !i.active ? `${i.name} (inactive)` : i.name,
          value: i.id,
        })),
      client
        .get("organisations")
        .get("data")
        .map((i) => ({
          label: i.organisation_name,
          value: i.organisation_name,
        })),
    ]);

    setFieldValue("regionalLeaderOptions", regionalLeaderOptions);
    setFieldValue("organisationNameOptions", organisationNameOptions);
    setFieldValue("regionOptions", regionOptions);
  }, []);

  async function load() {
    let promises = [
      client
        .post("dashboard/parcel_golden_numbers", {
          year,
          regions,
          organisation_names,
          regional_leaders,
          organisation_type,
          tags,
          date_from,
          date_to
        })
        .get("data"),
    ];

    if (isTLG) {
      promises = [
        ...promises,
        client
          .post("dashboard/demographics", {
            year,
            regions,
            organisation_names,
            regional_leaders,
            organisation_type,
            tags,
            date_from,
            date_to
          })
          .get("data"),
        client
          .post("dashboard/accessing_support_numbers", {
            year,
            regions,
            organisation_names,
            regional_leaders,
            organisation_type,
            tags,
            date_from,
            date_to
          })
          .get("data"),
        client
          .post("dashboard/golden_numbers", {
            year,
            regions,
            organisation_names,
            regional_leaders,
            organisation_type,
            tags,
            date_from,
            date_to
          })
          .get("data"),
        client
          .post("dashboard/combined_golden_numbers", {
            year,
            regions,
            organisation_names,
            regional_leaders,
            organisation_type,
            tags,
            date_from,
            date_to
          })
          .get("data"),
        client.get('dashboard/user_ethnicity').get('data'),
        client.get('dashboard/participant_ethnicity').get('data'),
        client.get('dashboard/volunteer_ages').get('data'),
        client.post('dashboard/metrics', {
          year,
          regions,
          organisation_names,
          regional_leaders,
          organisation_type,
          tags,
          date_from,
          date_to
        }).get('data'),
        client.post('dashboard/participant_feedback', {
          year,
          regions,
          organisation_names,
          regional_leaders,
          organisation_type,
          tags,
          date_from,
          date_to
        }).get('data'),
        client.post('dashboard/ready_steady_cook_stats', {
          regions,
          organisation_names,
          regional_leaders,
          organisation_type,
          tags,
          year,
          date_from,
          date_to
        }).get('data')
      ]
    }
    const [
      parcelGoldenNumbers,
      demographics,
      accessingSupportNumbers,
      goldenNumbers,
      combinedGoldenNumbers,
      userEthnicity,
      participantEthnicity,
      volunteerAges,
      metrics,
      participantFeedback,
      readySteadyCookStats
    ] = await Promise.all(promises);
    setFieldValue("demographics", demographics);
    setFieldValue("combinedGoldenNumbers", combinedGoldenNumbers);
    setFieldValue("parcelGoldenNumbers", parcelGoldenNumbers);
    setFieldValue("goldenNumbers", goldenNumbers);
    setFieldValue("accessingSupportNumbers", accessingSupportNumbers);
    setFieldValue('userEthnicity', userEthnicity);
    setFieldValue('participantEthnicity', participantEthnicity);
    setFieldValue('volunteerAges', volunteerAges);
    setFieldValue('metrics', metrics);
    setFieldValue('participantFeedback', participantFeedback);
    setFieldValue('readySteadyCookStats', readySteadyCookStats);
  }

  async function onSubmit() {
    setSubmitting(true);

    try {
      await load();
    } finally {
      setSubmitting(false);
    }
  }

  async function onExportCombinedYear() {
    setSubmitting(true);

    try {
      const data = await client
        .post("dashboard/export_combined_year", {
          year,
          organisation_type,
          tags,
          regions,
          regional_leaders,
          organisation_names,
          date_from,
          date_to
        })
        .get("data");
      download(data, "combined_year_export.csv", "text/csv");
    } catch (ex) {
      const code = get(ex, "response.data.code");

      if (code === "no_year_export") {
        NotificationManager.warning(
          "Year exports are not available for previous years"
        );
      } else if (code === 'no_data') {
        NotificationManager.warning('No data available to export for the requested filters');
      } else {
        throw ex;
      }
    } finally {
      setSubmitting(false);
    }
  }

  async function onExportParcelProvisionYear() {
    setSubmitting(true);

    try {
      const data = await client
        .post("dashboard/export_parcel_provision_year", {
          year,
          organisation_type,
          tags,
          regions,
          regional_leaders,
          organisation_names,
          date_from,
          date_to
        })
        .get("data");
      download(data, "parcel_provision_year_export.csv", "text/csv");
    } catch (ex) {
      const code = get(ex, "response.data.code");

      if (code === "no_year_export") {
        NotificationManager.warning(
          "Year exports are not available for previous years"
        );
      } else if (code === 'no_data') {
        NotificationManager.warning('No data available to export for the requested filters');
      } else {
        throw ex;
      }
    } finally {
      setSubmitting(false);
    }
  }

  async function onViewSurveyAnswers() {
    setSubmitting(true);

    try {
      const data = await client
        .post("dashboard/survey_free_text_answers", {
          year: year,
          organisation_type,
          tags,
          regions,
          regional_leaders,
          organisation_names,
          date_from,
          date_to
        })
        .get("data");
      setFreeTextAnswers(data);
      setIsFreeTextAnswerModalOpen(true);
    } finally {
      setSubmitting(false);
    }
  }

  async function onExportSurveys() {
    setSubmitting(true);

    try {
      const data = await client
        .post("dashboard/export_surveys", {
          year: year,
          organisation_type,
          tags,
          regions,
          regional_leaders,
          organisation_names,
          date_from,
          date_to
        })
        .get("data");
      download(data, "survey_free_text_answers.csv", "text/csv");
    } finally {
      setSubmitting(false);
    }
  }

  async function onExportLunchClubYear() {
    setSubmitting(true);

    try {
      const data = await client
        .post("dashboard/export_lunch_club_year", {
          year: year,
          organisation_type,
          tags,
          regions,
          regional_leaders,
          organisation_names,
          date_from,
          date_to
        })
        .get("data");
      download(data, "lunch_club_year_export.csv", "text/csv");
    } catch (ex) {
      const code = get(ex, "response.data.code");

      if (code === "no_year_export") {
        NotificationManager.warning(
          "Year exports are not available for previous years"
        );
      } else if (code === 'no_data') {
        NotificationManager.warning('No data available to export for the requested filters');
      } else {
        throw ex;
      }
    } finally {
      setSubmitting(false);
    }
  }

  const years = useMemo(() => {
    return [
      ...YEARS,
      ...(isStaff ? [{
        label: 'Custom range',
        value: null
      }] : [])
    ]
  }, [isStaff]);

  useEffect(() => {
    setFieldValue('date_from', null);
    setFieldValue('date_to', null);
  }, [year, setFieldValue])

  if (!regionOptions) return null;

  return (
    <Page title="Dashboard">
      {!isStaff && (
        <p className="form-text text-muted small tw--mt-3">
          Dashboard numbers are automatically submitted to TLG. No additional
          data, registration information or identifying information of children
          or adults is shared with TLG
        </p>
      )}
      {isFreeTextAnswerModalOpen ? (
        <FreeTextAnswerModal size="xl">
          <ModalHeader toggle={toggleFreeTextAnswerModal}>
            Survey Free Text Answers
          </ModalHeader>
          <ModalBody>
            <SurveyFreeTextAnswers showOrganisation={isStaff} data={freeTextAnswers} />
          </ModalBody>
        </FreeTextAnswerModal>
      ) : null}
      <div>
        <FormGroup>
          <Field
            component={Select}
            name="year"
            options={years}
            simpleValue
            searchable={false}
          />
        </FormGroup>
        {isStaff ? (
          <>
            {year === null && (
              <Row form>
                <Col>
                  <FormGroup label="Date From" name="date_from">
                    <Field
                      component={DatePicker}
                      name="date_from"
                      className="form-control"
                      validate={v => !v}
                      showYearDropdown
                      showMonthDropdown
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup label="Date To" name="date_to">
                    <Field
                      component={DatePicker}
                      name="date_to"
                      className="form-control"
                      validate={v => !v}
                      showYearDropdown
                      showMonthDropdown
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <FormGroup label="Regions">
              <Field
                component={Select}
                name="regions"
                options={regionOptions}
                isMulti
                simpleValue
              />
            </FormGroup>
            <FormGroup label="Church Relationship Leads">
              <Field
                component={Select}
                name="regional_leaders"
                options={regionalLeaderOptions}
                isMulti
                simpleValue
              />
            </FormGroup>
            <FormGroup label="Club Names">
              <Field
                component={Select}
                name="organisation_names"
                options={organisationNameOptions}
                isMulti
                simpleValue
              />
            </FormGroup>
            {isTLG ? (
              <FormGroup label="Club Type">
                <Field
                  component={Select}
                  name="organisation_type"
                  options={organisationTypes}
                  simpleValue
                />
              </FormGroup>
            ) : null}
            <FormGroup label="Meals & More Funding">
              <Field
                component={Select}
                name="tags"
                options={mealsAndMoreFundingOptions}
                isMulti
                simpleValue
              />
            </FormGroup>
          </>
        ) : null}
        <div className="tw-flex tw-justify-end tw-mb-4">
          {isStaff ? (
            <div className="tw-mr-auto">
              {isTLG ? (
                <SubmitButton
                  outline
                  disabled={!isValid}
                  className="tw-mr-2"
                  onClick={onExportLunchClubYear}
                >
                  Export Lunch Club Year
                </SubmitButton>
              ) : null}
              <SubmitButton
                outline
                className="tw-mr-2"
                disabled={!isValid}
                onClick={onExportParcelProvisionYear}
              >
                Export Parcel Provision Year
              </SubmitButton>
              {isTLG ? (
                <SubmitButton className="tw-mr-2" disabled={!isValid} outline onClick={onExportCombinedYear}>
                  Export Combined Year
                </SubmitButton>
              ) : null}
            </div>
          ) : null}
          <SubmitButton disabled={!isValid} onClick={onSubmit}>Filter</SubmitButton>
        </div>
      </div>
      {goldenNumbers ? (
        <GoldenNumbers isStaff={isStaff} data={goldenNumbers} />
      ) : null}
      {parcelGoldenNumbers ? (
        <ParcelGoldenNumbers isStaff={isStaff} data={parcelGoldenNumbers} />
      ) : null}
      {combinedGoldenNumbers ? (
        <CombinedGoldenNumbers isStaff={isStaff} data={combinedGoldenNumbers} />
      ) : null}
      {accessingSupportNumbers ? (
        <AccessingSupportNumbers data={accessingSupportNumbers} />
      ) : null}
      {demographics ? (
        <>
          <CollapseHeader
            isOpen={isDemographicsOpen}
            setIsOpen={setIsDemographicsOpen}
            title="Data Insights"
          />

          <Collapse isOpen={isDemographicsOpen} className="tw-py-4">
            <Demographics data={demographics} />

            {metrics ? (
              <>
                <ActivityGraphs activityCounts={metrics.activity_counts}
                                activityOrganisationCounts={metrics.activity_organisation_counts}
                />
                <hr/>
                <div className="tw-flex tw-justify-end">
                  <ActivitiesOtherData data={metrics.activity_counts.activities} />
                </div>
                <hr/>
                <ReturningRegistrationsGraph data={metrics.returning_registrations} />
                <hr/>
                <RegistrationStatementsGraph data={metrics.registration_statements} />
              </>
            ) : null}
            {(participantEthnicity && participantEthnicity.length) ? (
              <>
                <hr/>
                <EthnicityGraph title="Participant Ethnicity Data" data={participantEthnicity} />
                <div className="tw-flex tw-justify-end">
                  <EthnicityCaptureOtherData url="dashboard/participant_ethnicity_full_data" />
                </div>
              </>

            ) : null}
          </Collapse>
        </>
      ) : null}
      {participantFeedback && participantFeedback.num_results ? (
        <>
          <CollapseHeader
            isOpen={isParticipantFeedbackOpen}
            setIsOpen={setIsParticipantFeedbackOpen}
            title="Participant Feedback"
          >
          </CollapseHeader>
          <Collapse isOpen={isParticipantFeedbackOpen} className="tw-py-4">
            <ParticipantFeedbackGraph participantFeedback={participantFeedback} />
            <hr/>
            <div className="tw-flex tw-gap-x-2">
              <SubmitButton disabled={!isValid} outline className="" onClick={onExportSurveys}>
                Export Surveys
              </SubmitButton>
              <SubmitButton disabled={!isValid} outline className="" onClick={onViewSurveyAnswers}>
                View Survey Answers
              </SubmitButton>
            </div>
          </Collapse>
        </>
      ) : null}
      {readySteadyCookStats ? (
        <>
          <CollapseHeader
            isOpen={isReadySteadyCookOpen}
            setIsOpen={setIsReadySteadyCookOpen}
            title="Ready Steady Cook Compliance"
          >
          </CollapseHeader>
          <Collapse isOpen={isReadySteadyCookOpen} className="tw-py-4">
            <ReadySteadyCookGraph readySteadyCookStats={readySteadyCookStats} />
          </Collapse>
        </>
      ) : null}
      {isStaff ? (
        <>
          <CollapseHeader
            isOpen={isVolunteersOpen}
            setIsOpen={setIsVolunteersOpen}
            title="Volunteer Insights"
          >
          </CollapseHeader>
          <Collapse isOpen={isVolunteersOpen} className="tw-py-4">
            {metrics ? (
              <>
                <AverageVolunteersPerSession data={metrics.average_volunteers_per_session} />
                <hr/>
              </>
            ) : null}
            {userEthnicity ? (
              <>
                <EthnicityGraph data={userEthnicity} />
                <div className="tw-flex tw-justify-end">
                  <EthnicityCaptureOtherData />
                </div>
              </>

            ) : null}
            <div className="mb-4" />
            {volunteerAges ? (
              <VolunteerAgeGraph data={volunteerAges} />
            ) : null}
          </Collapse>
        </>
      ) : null}
    </Page>
  );
};

export default (props) => {
  const { isStaff, isBoxesOfHope } = useUserContext();
  return (
    <Formik
      initialValues={{
        yearOptions: [],
        year: THIS_YEAR,
        regions: [],
        regional_leaders: [],
        organisation_names: [],
        tags: [],
        organisation_type: isStaff ? (
          isBoxesOfHope ? 'boh' : 'tlg'
        ) : undefined
      }}
    >
      <Dashboard {...props} />
    </Formik>
  );
};
