import {FormProvider, useForm} from "react-hook-form";
import {useClient} from "../../../hooks";
import download from "downloadjs";
import {Button, Col, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {FormGroup} from "../../../components/common";
import {DatePicker, FormText, Input, SubmitButton} from "../../../@coherent/react-hook-form";
import React from "react";

export const ChurchSuiteExport = ({setIsOpen}) => {
    const form = useForm({
        defaultValues: {
            date_from: null,
            date_to: null,
            sites: ""
        }
    })
    const {handleSubmit} = form;
    const client = useClient();

    async function onExport(x) {
        const data = await client.post("registered/churchsuite_export", x).get("data");
        download(data, "churchsuite_import.csv", "text/csv");
        setIsOpen(false)
    }

    return (
        <FormProvider {...form}>
            <ModalHeader>
                Export Registrations to ChurchSuite
            </ModalHeader>
            <ModalBody>
                <p className="text-muted">
                    Creates a CSV file compatible with the ChurchSuite import.
                </p>
                <Row form>
                    <Col>
                        <FormGroup label="Date from" name="date_from">
                            <DatePicker name="date_from" className="form-control"/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label="Date to" name="date_to">
                            <DatePicker name="date_to" className="form-control"/>
                        </FormGroup>
                    </Col>
                </Row>
                <FormText className="tw--mt-3 tw-mb-4">
                    Filters the Registrations by creation date. Leave blank to export all registrations.
                </FormText>
                <FormGroup label="Sites">
                    <Input name="sites" className="form-control"/>
                    <FormText>
                        If you would like these contacts to be stored in a specific site within Churchsuite that you already have set up, please enter the site name here.
                    </FormText>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
                <div className="tw-mx-auto"/>
                <SubmitButton onClick={handleSubmit(onExport)}>
                    Export
                </SubmitButton>
            </ModalFooter>
        </FormProvider>
    );
};