import React from "react";
import cx from "classnames";
import { formatDateTime } from "../applications/util";
import { ConfirmButton } from "../applications/components/ConfirmButton";
import { Link } from "react-router-dom";

export const ApplicationSafeguardingDeclarationRow = ({
  allowSend,
  allowLink,
  value,
  onSendEmail,
}) => {
  const { email_sent, email, submitted, year, id } = value;

  return (
    <tr>
      <td>{email}</td>
      <td>{year}</td>
      <td>
          {allowLink ? (
              <Link to={`/safeguarding_declarations/${id}`}>Link</Link>
          ) : null}
      </td>
      <td>
        <ul className="plain-ul">
          <li>
            {email_sent ? (
              <>
                <span className="fa fa-check-circle text-success" /> Sent on{" "}
                <strong>{formatDateTime(email_sent)}</strong>
              </>
            ) : (
              <>
                <span className="fa fa-times-circle text-danger" /> Not sent
              </>
            )}
          </li>
          <li>
            <span
              className={cx("fa", {
                "fa-times-circle text-danger": !submitted,
                "fa-check-circle text-success": submitted,
              })}
            />
            {submitted ? (
              <>
                {" "}
                Submitted on <strong>{formatDateTime(submitted)}</strong>
              </>
            ) : (
              " Not submitted"
            )}{" "}
          </li>
        </ul>
      </td>
      <td>
        {allowSend ? (
          <ConfirmButton
            size="sm"
            onClick={() => onSendEmail(value)}
            confirmProps={{
              color: "warning",
              children: (
                <>
                  <span className="fa fa-envelope mr-2" /> Confirm send?
                </>
              ),
            }}
          >
            <span className="fa fa-envelope mr-2" /> Send Safeguarding
            Declaration
          </ConfirmButton>
        ) : null}
      </td>
    </tr>
  );
};
