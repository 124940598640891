import React, {useState} from "react";
import {useAsyncEffect, useClient, useModal, usePendingButton, useQueryState, useTable, useUserContext,} from "hooks";
import {FormGroup, Page} from "components/common";
import {Badge, Button, Col, ModalBody, ModalFooter, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import download from "downloadjs";
import {useDropzone} from "react-dropzone";
import {isEmpty, keys, map, mapKeys, range} from "lodash";
import {NotificationManager} from "react-notifications";
import Select from "react-select";
import {age} from "./utils";
import {BulkConnectionStatusButton} from "./BulkConnectionStatusButton";
import {BulkArchiveButton} from "../../components/common/Tables/BulkArchiveButton";
import {OrganisationRegistrationLink} from "../../features/participant_registration/OrganisationRegistrationLink";
import {formatDateTime} from "../../features/applications/util";
import {ChurchSuiteExport} from "../../features/churchsuite/components/ChurchSuiteExport";

export const Import = ({ setIsOpen, onSuccess, options }) => {
  const [file, setFile] = useState();
  const [errors, setErrors] = useState();
  const [SubmitButton] = usePendingButton();
  const client = useClient();
  const headers = keys(options);
  const r = range(8);
  const { isTLG, isBoxesOfHope } = useUserContext();

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    multiple: false,
  });

  function onDropAccepted([file]) {
    const reader = new FileReader();
    reader.onload = () => {
      const result = {
        filename: file.name,
        content: reader.result,
      };
      setFile(result);
    };
    reader.readAsDataURL(file);
  }

  async function onImport() {
    setErrors(null);
    try {
      await client.post("registered/import", file);
      setIsOpen(false);
      NotificationManager.success("Imported successfully");
      onSuccess();
    } catch (err) {
      const { code, detail } = client.parseError(err);

      if (code === "invalid") {
        setErrors(detail);
        NotificationManager.warning(
          "There were some problems importing the file"
        );
      } else if (code === "UnableToReadFile") {
        NotificationManager.error(
          "Could not read the selected file, please make sure it is a valid CSV file"
        );
      } else {
        throw err;
      }
    }
  }

  return (
    <>
      <ModalBody>
        <div
          className="tw-border tw-border-solid tw-rounded tw-p-3 tw-mt-2"
          {...getRootProps()}
        >
          {file ? (
            <>{file.filename} attached</>
          ) : (
            "Click here to attach a *.csv file"
          )}

          <input {...getInputProps()} />
        </div>
        <div className="tw-my-4">
          {isBoxesOfHope && (
            <a href="/api/registered/import_test_file_boh" download>
              Download test file
            </a>
          )}
          {isTLG && (
            <a href="/api/registered/import_test_file" download>
              Download test file
            </a>
          )}

        </div>
        {errors && errors.length ? (
          <>
            <hr />
            <h5>Issues with the file</h5>
            <ul>
              {errors.map((i, idx) =>
                isEmpty(i) ? null : (
                  <li key={idx}>
                    Row {idx + 1}
                    <ul>
                      {map(i, (v, k) => (
                        <li key={k}>
                          {k}{" "}
                          <ul>
                            {v.map((j) => (
                              <li key={j}>{j}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </ul>
          </>
        ) : null}
        {isTLG && (
          <>
            <hr />
            <h5>Field Options</h5>
            <table className="table table-condensed table-sm">
              <thead>
              <tr>
                {headers.map((i) => (
                  <th key={i}>{i}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {r.map((i) => (
                <tr key={i}>
                  {headers.map((j) => (
                    <td key={j}>{options[j][i]}</td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => setIsOpen(false)}>
          Close
        </Button>
        <div className="tw-mx-auto" />
        <SubmitButton disabled={!file} onClick={onImport}>
          Import
        </SubmitButton>
      </ModalFooter>
    </>
  );
};

const archiveButtonContent = (
  "Archiving will hide this registration from the Add Attendance dropdowns and Registration list, it does not " +
  "affect existing data on your dashboard and can be undone at any time by un ticking the Archive tick box"
);

export const List = ({ match, client, history }) => {
  const [archived, setArchived] = useQueryState('archived', false);
  const [selfRegistered, setSelfRegistered] = useQueryState('self_registered', false);

  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    Td,
    Th,
    setFilters,
    refreshData,
    selected,
    filters,
    search,
  } = useTable({
    match,
    url: "registered",
    filters: {
      soft_archived: archived
    }
  });
  const [ExportButton] = usePendingButton();
  const { allowMultipleGroups, isTLG, isBoxesOfHope, user } = useUserContext();
  const { isOpen, setIsOpen, Modal } = useModal();
  const {
    isOpen: isChurchSuiteExportOpen,
    setIsOpen: setIsChurchSuiteExportOpen,
    Modal: ChurchSuiteExportModal
  } = useModal();
  const [options, setOptions] = useState();
  const [isParcelDeliveryContact, setIsParcelDeliveryContact] = useState(false);
  const [attendanceType, setAttendanceType] = useState();
  const [attendanceTypes, setAttendanceTypes] = useState();

  async function onExport() {
    const data = await client.post("registered/export", null, {
      params: {
        ...filters,
        search
      }
    }).get("data");
    download(data, "registered_export.csv", "text/csv");
  }

  const accessingWiderSupportTypes = {
    yes_single: 'Yes - once',
    yes_occasionally: 'Yes - occasionally',
    yes_consistently: 'Yes - consistently'
  }

  useAsyncEffect(async () => {
    const mapping = {
      attendance_type: "Adult Attendance Types",
      gender: "Gender",
    };
    const options = await client.get("registered/options").get("data");
    delete options.schools;
    setAttendanceTypes(
      [
        ...options.attendance_type.map((i) => ({
          label: i,
          value: i,
        })),
        {
          label: 'Child',
          value: 'Child'
        }
      ]
    );
    setOptions(mapKeys(options, (v, k) => mapping[k]));
  }, []);

  const deleteBody = "This action will delete this record from your database, so you will no longer be able to " +
    "access this individuals registration information, however it will not affect any Golden Numbers that have " +
    "already been recorded on your dashboard."

  return (
    <Page title="Registration">
      {isOpen && (
        <Modal size="lg">
          <Import
            onSuccess={refreshData}
            options={options}
            setIsOpen={setIsOpen}
          />
        </Modal>
      )}
      {isChurchSuiteExportOpen && (
          <ChurchSuiteExportModal size="lg">
            <ChurchSuiteExport setIsOpen={setIsChurchSuiteExportOpen} />
          </ChurchSuiteExportModal>
      )}
      <TableSearch className="fa-min" />

      {isTLG && (
        <Row form className="mb-4">
          <Col sm="auto">
            <FormGroup
              label="Attendance Type"
              className="mb-0"
              style={{ width: "20rem" }}
            >
              <Select
                isClearable
                options={attendanceTypes}
                onChange={(v) => {
                  setAttendanceType(v);
                  setFilters((filters) => ({
                    ...filters,
                    attendance_type: v ? v.value : undefined,
                  }));
                }}
                value={attendanceType}
              />
            </FormGroup>
          </Col>
          <Col sm="auto" className="align-self-end pl-2">
            <FormGroup label="Parcel Delivery Contact" check className="mb-1">
              <input
                type="checkbox"
                className="form-check-input"
                checked={isParcelDeliveryContact}
                onChange={(e) => {
                  const { checked } = e.target;
                  setIsParcelDeliveryContact(!!checked);
                  setFilters((v) => ({
                    ...v,
                    parcel_delivery_contact: !!checked || undefined,
                  }));
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="auto" className="align-self-end pl-2">
            <FormGroup label="View Archived" check className="mb-1">
              <input
                type="checkbox"
                className="form-check-input"
                checked={archived}
                onChange={(e) => {
                  const { checked } = e.target;
                  setArchived(!!checked)
                  setFilters((v) => ({
                    ...v,
                    soft_archived: !!checked,
                  }));
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="auto" className="align-self-end pl-2">
            <FormGroup label="View Self-registered" check className="mb-1">
              <input
                type="checkbox"
                className="form-check-input"
                checked={selfRegistered}
                onChange={(e) => {
                  const { checked } = e.target;
                  setSelfRegistered(checked || undefined)
                  setFilters((v) => ({
                    ...v,
                    self_registered: checked || undefined,
                  }));
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <div className="tw-flex tw-mb-3 tw-items-start tw-flex-wrap tw-space-y-2" style={{
        rowGap: '0.5rem',
        columnGap: '0.5rem'
      }}>
        {isTLG && (
          <>
            {user.organisation__hashid ? (
              <OrganisationRegistrationLink hashid={user.organisation__hashid} organisationName={user.organisation__organisation_name} />
            ) : null}
            <AddNewButton className={["btn-outline-tlg-teal", {
              'btn-tlg-teal': false
            }]}>
              <span className="fa fa-plus mr-1" /> Register New Child
            </AddNewButton>
            <AddNewButton className={["btn-outline-tlg-teal", {
              'btn-tlg-teal': false
            }]} query={{ adult: true }}>
              <span className="fa fa-plus mr-1" /> Register New Adult
            </AddNewButton>
          </>
        )}
        {isBoxesOfHope && (
          <AddNewButton query={{ adult: true }}>
            <span className="fa fa-plus mr-1" /> Register New BoH Family
          </AddNewButton>
        )}

        <div className="tw-mx-auto" />
        <ExportButton onClick={() => setIsOpen(true)}>
          <span className="fa fa-upload tw-mr-1" />
          Import
        </ExportButton>
        <ExportButton onClick={onExport}>
          <span className="fa fa-file-excel-o tw-mr-1" />
          Export
        </ExportButton>
        <ExportButton onClick={() => setIsChurchSuiteExportOpen(true)}>
          <span className="fa fa-file-excel-o tw-mr-1" />
          Export to ChurchSuite
        </ExportButton>
        <BulkDeleteButton title={deleteBody} appendBody={deleteBody} />
        <BulkArchiveButton content={archiveButtonContent} baseUrl="registered" attr="soft_archived" onSubmitted={() => {
          refreshData();
        }} archived={archived} selected={selected} />
        <BulkConnectionStatusButton onSubmitted={() => {
          refreshData();
        }} selected={selected} />
        <Link to="/registered_duplicates" className="btn btn-primary">
          Registered duplicates
        </Link>
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="first_name">First Name</th>
              <th sortBy="last_name">Surname</th>
              {isTLG && (
                <>
                  <Th screen="sm" sortBy="gender">
                    Gender
                  </Th>
                  <Th screen="sm" sortBy="date_of_birth">
                    Age
                  </Th>
                  <Th screen="sm" sortBy="attendance_type">
                    Attendance Type
                  </Th>
                  <Th screen="sm" sortBy="accessing_wider_support">
                    Wider Support
                  </Th>
                  {allowMultipleGroups && <Th screen="sm">Groups</Th>}
                </>
              )}
              <th sortBy="created">
                Created
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.first_name}</Link>
                </td>
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.last_name}</Link>
                </td>
                {isTLG && (
                  <>
                    <Td screen="sm">{i.gender}</Td>
                    <Td screen="sm">{age(i.date_of_birth)}</Td>
                    <Td screen="sm">
                      {i.attendance_type_full}
                    </Td>
                    <Td screen="sm">{i.accessing_wider_support ? accessingWiderSupportTypes[i.accessing_wider_support_type] || 'Yes' : "No"}</Td>
                    {allowMultipleGroups && <Td screen="sm">{i.num_groups}</Td>}
                  </>
                )}
                <td>
                  {i.created ? formatDateTime(i.created) : ''}
                  {i.participant_self_registration ? (
                    <Badge color="light" pill>Self-registered</Badge>
                  ) : null}
                </td>
                <td className="text-right tw-whitespace-no-wrap">
                  {i.parcel_delivery_contact ? (
                    <Link
                      className="btn btn-primary btn-sm mr-2"
                      to={{
                        pathname: "/parcel_provision_sessions",
                        search: `registered=${i.id}`,
                      }}
                    >
                      View Parcel Provisions
                    </Link>
                  ) : null}
                  {isTLG && (
                    <Link
                      className="btn btn-primary btn-sm"
                      to={{
                        pathname: "/sessions",
                        search: `registered=${i.id}`,
                      }}
                    >
                      View Sessions
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
